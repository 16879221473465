import classNames from 'classnames'
import * as React from 'react'

import {componentNames} from '@/common/enums'
import {makeEditable} from '@/common/utils'
import DynamicComponent from '@/components/DynamicComponent'

const Statistics = ({items, backgroundColor, _editable}) => {
  return (
    <section
      {...makeEditable(_editable)}
      className={classNames(
        'my-4 flex w-full flex-col items-center justify-center gap-8 rounded-lg px-5 py-6 md:flex-row lg:items-baseline',
        {
          ['background-' + backgroundColor]: backgroundColor,
        }
      )}
    >
      {items.map((props) => {
        if (props.component !== componentNames.STATISTIC_ITEM) return null
        return (
          <div key={props._uid}>
            <DynamicComponent {...props} />
          </div>
        )
      })}
    </section>
  )
}

export default Statistics

import classNames from 'classnames'
import * as React from 'react'
import {NODE_IMAGE, render} from 'storyblok-rich-text-react-renderer'

import {componentNames} from '@/common/enums'
import {isEmptyRichtext} from '@/common/utils'
import BlogButton from '@/components/BlogButton/BlogButton'
import Image from '@/components/Image'

import BlogLink from '../../BlogLink/BlogLink'
import Quote from '../../Quote/Quote'
import Statistics from '../../Statistics/Statistics'

const BlogPostRichtext = ({richtext, foregroundColor, backgroundColor}) => {
  if (isEmptyRichtext(richtext)) return null

  return (
    <article
      className={classNames('prose-h2:heading-xs-regular prose-p:body-s-regular prose max-w-none', {
        ['prose-a:textcolor-' + foregroundColor]: foregroundColor,
      })}
    >
      {render(richtext, {
        nodeResolvers: {
          [NODE_IMAGE]: (_, props) => (
            <div className='my-4'>
              <Image src={props.src} alt={props.alt} />
            </div>
          ),
        },

        defaultBlokResolver: (name, props) => {
          switch (name) {
            case 'blog-video':
              return (
                <div
                  dangerouslySetInnerHTML={{__html: props.iframe}}
                  className='aspect-h-9 aspect-w-16 my-5 md:my-6 lg:my-8 [&_iframe]:h-full [&_iframe]:w-full'
                />
              )
            case 'quote':
              return (
                <Quote
                  {...props}
                  _uid=''
                  component={componentNames.QUOTE}
                  foregroundColor={foregroundColor}
                  backgroundColor={backgroundColor}
                />
              )
            case 'statistics':
              return (
                <Statistics
                  {...props}
                  _uid=''
                  component={componentNames.STATISTICS}
                  backgroundColor={backgroundColor}
                />
              )
            case 'blog-link':
              return (
                <BlogLink
                  {...props}
                  _uid=''
                  component={componentNames.BLOG_LINK}
                  foregroundColor={foregroundColor}
                  backgroundColor={backgroundColor}
                />
              )
            case 'blog-button':
              return <BlogButton {...props} _uid='' component={componentNames.BLOG_BUTTON} />
            default:
              return null
          }
        },
      })}
    </article>
  )
}

export default BlogPostRichtext

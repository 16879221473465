import classNames from 'classnames'
import React from 'react'

import {getCategoryColors, getCategoryLayout} from '@/common/utils'
import BlogPostRichtext from '@/components/Blog/BlogPostRichtext/BlogPostRichtext'
import DynamicComponent from '@/components/DynamicComponent'
import HeadComponent from '@/components/Head'
import Image from '@/components/Image'
import Layout from '@/templates/Layout'

const Post = (data) => {
  let story

  if (data.pageContext) {
    story = data.pageContext
  } else {
    story = data.content
  }

  if (!story) {
    return null
  }
  const richtext = story.richtext

  const categoryLayout = getCategoryLayout(story.category)
  const {backgroundColor} = getCategoryColors(story.category)
  const heroComponents = story.hero ? story.hero : []

  return (
    <Layout {...categoryLayout} backgroundColor={backgroundColor}>
      <main className={classNames({[`background-${backgroundColor}`]: backgroundColor})}>
        {heroComponents.map((props) => (
          <DynamicComponent key={props._uid} {...props} />
        ))}

        <div className={'pelo-grid gap-y-7 py-7'}>
          <div className='col-span-full rounded-[56px] bg-grey000 lg:col-span-10 lg:col-start-2'>
            {story.cover && story.cover.filename && (
              <Image
                src={story.cover.filename}
                alt={story.heading}
                className='rounded-t-[56px]'
                sizes='(min-width: 1280px) 1000px, 100vw'
              />
            )}

            <div className='space-y-4 py-5 px-2 md:px-7 md:py-10 lg:px-26'>
              <BlogPostRichtext
                richtext={richtext}
                foregroundColor={story.category?.content.foregroundColor}
                backgroundColor={story.category?.content.backgroundColor}
              />
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export const Head = ({pageContext}) => <HeadComponent {...pageContext} />

export default Post

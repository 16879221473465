import classNames from 'classnames'
import * as React from 'react'

import {componentNames} from '@/common/enums'
import {makeEditable} from '@/common/utils'
import DynamicComponent from '@/components/DynamicComponent'

const Quote = ({quote, author, foregroundColor, backgroundColor, _editable}) => {
  return (
    <section {...makeEditable(_editable)}>
      <figure>
        <blockquote
          className={classNames('p-2', {
            ['background-' + backgroundColor]: backgroundColor,
          })}
        >
          <span
            className={classNames('body-l-regular', {
              ['textcolor-' + foregroundColor]: foregroundColor,
            })}
          >
            {quote}
          </span>

          {author &&
            author.map((props) => {
              if (props.component !== componentNames.AUTHOR) return null
              return (
                <DynamicComponent
                  {...props}
                  key={props._uid}
                  foregroundColor={foregroundColor}
                  className='mt-4'
                />
              )
            })}
        </blockquote>
      </figure>
    </section>
  )
}

export default Quote

import * as React from 'react'

import {makeEditable} from '@/common/utils'

import Button from '../Button/Button'

const BlogButton = ({label, link, _editable}) => {
  return (
    <section {...makeEditable(_editable)} className='mt-4 flex w-full justify-center'>
      <Button label={label} link={link} />
    </section>
  )
}

export default BlogButton

import classNames from 'classnames'
import * as React from 'react'

import {makeEditable} from '@/common/utils'

import Lien from '../Lien'

const BlogLink = ({label, link, foregroundColor, backgroundColor, _editable}) => {
  return (
    <section
      {...makeEditable(_editable)}
      className={classNames('my-4 flex h-12 items-center justify-center gap-1 rounded-lg', {
        ['background-' + backgroundColor]: backgroundColor,
      })}
    >
      <Lien
        link={link}
        className={classNames('body-l-regular py-4 text-center no-underline', {
          ['textcolor-' + foregroundColor]: foregroundColor,
        })}
      >
        {label}
      </Lien>
    </section>
  )
}

export default BlogLink
